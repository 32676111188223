<div class="container">
	<main class="main_container">
		<div class='box'>
			<app-loader *ngIf="showLoader"></app-loader>
			<h3 class="text-center">{{ 'UI.COMMON.DOWNLOAD_LOG' | translate }}</h3>
			<form name="downloadLogForm" (ngSubmit)="f.form.valid && downloadLog()" #f="ngForm" novalidate>
				<div class="alertSummary ng-hide" role="alert" *ngIf="f.submitted && startDate.errors">
                    <div *ngIf="startDate.errors.required">{{ 'UI.DATA_EXPORT.FROM_DATE_REQUIRED_MSG' | translate }}</div>
                </div>
				<div class="alertSummary ng-hide" role="alert" *ngIf="f.submitted && endDate.errors">
                    <div *ngIf="endDate.errors.required">{{ 'UI.DATA_EXPORT.END_DATE_REQUIRED_MSG' | translate }}</div>
                </div>
				<div class='loginForm'>
					<div class="form-group row gap">

						<label class="control-label col-sm-3" for="enddate">{{'UI.DATA_EXPORT.EXPORT_FROM_DATE_LABEL' |
							translate }}<span class="mandatory_field">*</span>:</label>
						<div class="col-8">
							<div class="input-group">
								<input 
									class="form-control" 
									name="dp" 
									ngbDatepicker 
									#d="ngbDatepicker"
									[(ngModel)]="downloadLogForm.startDate"
									(ngModelChange)="autoAssignEndDate()"
									[disabled]="isAllTime"
									#startDate="ngModel" 
									required
								/>
								<button class="btn btn-outline-secondary" [disabled]="isAllTime" (click)="d.toggle()" type="button">
									<img src="./../../../assets/img/calendar3.svg" alt="Image">
								</button>
							</div>
						</div>
					</div>

					<div class="form-group row gap">
						<label class="control-label col-sm-3" for="enddate">{{'UI.DATA_EXPORT.EXPORT_TO_DATE_LABEL' |
							translate }}<span class="mandatory_field">*</span>:</label>
						<div class="col-8">
							<div class="input-group">
								<input 
									class="form-control" 
									name="ap" 
									[(ngModel)]="downloadLogForm.endDate" 
									ngbDatepicker 
									#e="ngbDatepicker" 
									[disabled]="isAllTime" 
									#endDate="ngModel" 
									required
								/>
								<button class="btn btn-outline-secondary " (click)="e.toggle()" type="button" [disabled]="isAllTime" >
									<img src="./../../../assets/img/calendar3.svg" alt="Image">
								</button>
							</div>
						</div>


					</div>

					<br>
					<div class="form-group row gap">
						<div class="col-sm-2"></div>
						<div class="col-sm-10 checkbox">
							<label class="checkbox-label ng-binding">
								<input 
									name="disableInput" type="checkbox"
									(click)="checkAllTime()"
									[(ngModel)]="disableInput" />{{'UI.COMMON.ALL_TIME' | translate}}
							</label>
						</div>
					</div>
					<div class="row form-group form-footer gap">
						<div class="col-sm-2">
						</div>
						<div class="col-sm-4">
							<button type="submit" ng-click="submitted=true"
								class="btn btn-primary center-block form-control">{{'UI.COMMON.DOWNLOAD' |
								translate}}</button>
						</div>
						<div class="col-sm-4">
							<button (click)="cancelPage()" class="btn btn-primary form-control subs-cnl-btn">{{ 'UI.COMMON.CANCEL_BUTTON' | translate }}</button>
						</div>
						<div class="col-sm-2">
						</div>
					</div>
				</div>

			</form>
		</div>
	</main>
</div>
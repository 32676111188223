<div class="container">
    <main class="main_container">
        <div class='box'>
            <app-loader *ngIf="showLoader"></app-loader>
            <h3 class="text-center">{{ 'UI.COMMON.CHANGE_SUBSCRIPTION' | translate }}</h3>
            <form name="cropDiseaseSubscriptionForm" (ngSubmit)="cropDiseaseSubscription()" #f="ngForm" novalidate>
                <!-- <div class="alertSummary ng-hide" role="alert" *ngIf="mainError">
                    {{ mainError }}
                </div>
                <div class="alertSummary" *ngIf="f.form.touched && passcode.errors">
                    <div *ngIf="passcode.errors.required">{{ 'UI.PASSCODE_VERIFICATION.PASSCODE_REQUIRED_MSG' | translate }}</div>
                    <div *ngIf="passcode.errors.minlength">{{ 'UI.PASSCODE_VERIFICATION.PASSCODE_LENGTH_MSG' | translate: { passcodeLength: passcodeLength } }}</div>
                </div>
                <div class="alertSummary info" *ngIf="counter && counter > 0" [innerHtml]="'UI.COMMON.DSTE0004' | translate: { counter: counter }"></div>

                <div class="alertSummary info" *ngIf="infoMessageIndex != null && infoMessageIndex == 0">
                    {{ 'UI.PASSCODE_VERIFICATION.SEND_EMAIL_SUCCESS_MSG' | translate }}
                </div> -->

                <div class="alertSummary" *ngIf="!isCropSelected">
                    {{ 'UI.COMMON.CROP_REQUIRED_MSG' | translate }}
                </div>
                <div class='loginForm'>
                    <div class="form-group" >
                        <div class="row">
                            <label class="col-md-12" for="externalSensorId">{{ 'UI.COMMON.RESULT' | translate }}</label>
                        </div>
                        <div class="result-box">
                            <div>{{ externalSensorId }}</div>
                            <div>
                                {{ 'UI.COMMON.CROP' | translate }}: {{ cropName }}
                            </div>
                        </div>
                        <div class="row">
                            <label class="col-md-12" for="cropTypeDisease">{{ 'UI.COMMON.CROP' | translate }}<span class="mandatory_field">*</span></label>
                        </div>
                        <div class="controls">
                            <select class="form-select" (change)="diseaseForCrop()" name="cropTypeDisease" aria-label="Default select example" [(ngModel)]="cropDefaultValue">
                                <option *ngFor="let crop of cropTypeDisease; let i = index">{{ crop.description }}</option>
                              </select>
                        </div> <br>
                        <div class="row">
                            <label class="col-md-12" for="diseaseSubscription">{{ 'UI.COMMON.DISEASE_SUBSCRIPTION' | translate }}</label>
                        </div>
                        <div class="form-check">
                            
                            <label class="form-check-label" *ngFor="let disease of predictionDisease; let i = index" for="{{disease.id}}">
                                <input class="form-check-input" type="checkbox" name="{{disease.id}}" value="selectedDisease" id="{{disease.id}}" [(ngModel)]="disease.isSelected">
                              {{ disease.description }}
                            </label>
                        </div>

                        <div class="actionButtons">
                            <button class="btn btn-primary form-control subs-btn">{{ 'UI.COMMON.CHANGE' | translate }}</button>
                            <button (click)="cancelPage()" class="btn btn-primary form-control subs-cnl-btn">{{ 'UI.COMMON.CANCEL_BUTTON' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>
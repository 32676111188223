import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-validate-passcode',
  templateUrl: './validate-passcode.component.html',
  styleUrls: ['./validate-passcode.component.css']
})
export class ValidatePasscodeComponent implements OnInit {


  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _tokenStorage: TokenStorageService
  ) {}

  showLoader: boolean = true;
  counter: number = 0;
  mainError: any;

  ngOnInit(): void {
    this.sendPasscodeEmail();
    this._authService.isLoggedIn = true;
    const userDetails = localStorage.getItem('user_details');
    if (userDetails !== null) {
      this._authService.loggedInUsername = JSON.parse(userDetails).name;
    }
  }
  infoMessageIndex: number | null = null;
  passcodeLength = 4;
  passcodeResendCounter = 0;
  passcodeResendDuration = 30;
  passcodeVerificationForm: any = {
    passcode: null
  };

  sendPasscodeEmail(isResent ?: boolean) : void{
    const locale = localStorage.getItem("default_language") || 'en';
    this._authService.sendPasscodeEmail(locale).subscribe(data => {
      isResent ? this.infoMessageIndex = 1 : this.infoMessageIndex = 0;
      this.showLoader = false;
    }, err => {
      this.counter = err.error.timeToWait;
      this.showLoader = false;
      let passcodeTimeoutId = setInterval(() => {
        if (this.counter > 0) {
          this.counter = this.counter - 1;
        } else {
          clearInterval(passcodeTimeoutId);
        }
      }, 1000);
    })
  }
  resendPasscodeEmail():void{
    this.showLoader = true;
    this.infoMessageIndex = null;
    this.sendPasscodeEmail(true);
  }

  onKeyPress(event: KeyboardEvent) {
    this.mainError = false;
    this.infoMessageIndex = null;
  }
  onPaste(event: ClipboardEvent) {
    this.infoMessageIndex = null;
  }

  verifyPasscode(): void {
      this.showLoader = true;
      this._authService.verifyPasscode(this.passcodeVerificationForm.passcode).subscribe(res => {
        this._authService.userStatus=Constants.USER_AUTHENTICATED;
        this._router.navigate(['/landing']);
        const startInterval = setInterval(() => {
          if (!this._authService.isLoggedIn) {
            clearInterval(startInterval);
          }
          this._authService.refreshToken().subscribe(res => {
            this._tokenStorage.saveToken(res.access_token, res.refresh_token, res.expired_time);
          })
        }, 4 * 60 * 1000);
      },
      err => {
        if(err.error.returnCode === 'DSTE0005' && err.error.message === 'UI.HTTP.ERROR_CODE.MESSAGES.500') {
          this.mainError = 'UI.COMMON.DSTE0005';
        }
        this.showLoader = false;
      })
  }
}

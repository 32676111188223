<div id="app">
  <nav class="navbar navbar-expand-lg navbar-dark bg-light header">
      <div class="header-title">
        <a href="#" class="navbar-brand" *ngIf="!isShowJapaneseTitle">Disease Subscription Tool</a>
        <a href="#" class="navbar-brand" style="font-size: 1.2em;" *ngIf="isShowJapaneseTitle">病害予測機能オプション管理ツール</a>
      </div>
        <div class="header-title-lang">
            <ul class="navbar-nav ml-auto lang_menu" >
                <a class="js-hover lang_menu_item" (click)="toggleLangMenu()" [ngClass]="{ 'is-open' : isOpen}">{{ "Language" }}</a>
                <ul class="languageBar__body" id="language-bar" [ngStyle]="{'display':isOpen? 'block' : 'none'}">
                    <li><a id="jaLang" class="js-hover languageBar__item" style="text-align:center;" (click)="changeLang('ja')">
                        <span>{{ "日本語" }}</span></a></li>
                    <li><a id="enLang" class="js-hover languageBar__item" style="text-align:center;" (click)="changeLang('en')">
                        <span>{{ "EN" }}</span></a></li>
                </ul>
            </ul>
            <ul class="navbar-nav ml-auto lang_menu" *ngIf="_authService.isLoggedIn">
                <a 
                    class="js-hover lang_menu_item" 
                    (click)="toggleLogoutMenu()" 
                    [ngClass]="{ 'is-open' : isLogoutOptionOpen}"
                >
                    {{ _authService.loggedInUsername }}
                </a>
                    <ul class="languageBar__body" id="username-bar" [ngStyle]="{'display':isLogoutOptionOpen? 'block' : 'none'}">
                        <li><a id="jaLang" class="js-hover languageBar__item" style="text-align:center;" (click)="logout()">
                            <span>{{ 'UI.COMMON.LOGOUT' | translate }}</span></a></li>
                    </ul>
            </ul>
        </div>
  </nav>

  <div class="app-container">
      <router-outlet></router-outlet>
  </div>
</div>




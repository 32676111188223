import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './authentication.guard';
import { AccountLoginComponent } from './components/accounts/account-login/account-login.component';
import { ValidatePasscodeComponent } from './components/accounts/validate-passcode/validate-passcode.component';
import { ChangeSubscriptionComponent } from './components/change-subscription/change-subscription.component';
import { DownloadLogComponent } from './components/download-log/download-log.component';
import { LandingComponent } from './components/landing/landing.component';
import { NewResultComponent } from './components/new-result/new-result.component';

const routes: Routes = [
  { path: '', component: AccountLoginComponent, pathMatch: 'full', canActivate: [AuthenticationGuard]  },
  { path: 'login', component: AccountLoginComponent },
  { path: 'passcode', component: ValidatePasscodeComponent },
  { path: 'change-subscription', component: ChangeSubscriptionComponent, canActivate: [AuthenticationGuard] },
  { path: 'new-result', component: NewResultComponent, canActivate: [AuthenticationGuard] },
  { path: 'download-log', component: DownloadLogComponent, canActivate: [AuthenticationGuard] },
  { path: 'landing', component: LandingComponent, canActivate: [AuthenticationGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

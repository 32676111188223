import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterTestingHarness } from '@angular/router/testing';
import { Observable } from 'rxjs';
import { Constants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  userStatus: string = Constants.UNAUTHENTICATED;
  isLoggedIn: boolean = false;
  loggedInUsername: string = '';

  login(emailAddress: string, password: string): Observable<any> {

    const req =  {
      "grant_type": "password",
      "client_id": "fuji",
      "username": emailAddress,
      "password": password,
      "scope": "offline_access"
    }

    return this.http.post(Constants.API_ROUTES.LOGIN, req);
  }
  sendPasscodeEmail(locale: string): Observable<any>{
    const req = {
      "locale": locale
    };
    return this.http.put(Constants.API_ROUTES.SEND_PASSCODE_EMAIL+ `?locale=${locale}`, {});
  }

  verifyPasscode(passcode:string): Observable<any>{
    const req =  {
    }
    const url=Constants.API_ROUTES.VERIFY_PASSCODE+passcode;
    return this.http.put(url,req);
  }

  refreshToken(): Observable<any>{
    const refreshToken = localStorage.getItem("refresh_token");
    const req =  {
      "grant_type": "refresh_token",
      "refresh_token": refreshToken,
      "client_id": "fuji"
    }

    const headers = new HttpHeaders().set('skipInterceptor', 'true');
    return this.http.post(Constants.API_ROUTES.LOGIN, req, {
      headers: headers
    });
  }

  logout(): Observable<any> {
    const refreshToken = localStorage.getItem("refresh_token");
    const req =  {
      "grant_type": "logout",
      "refresh_token": refreshToken,
      "client_id": "fuji"
    }
    const headers = new HttpHeaders().set('skipInterceptor', 'true');
    return this.http.post(Constants.API_ROUTES.LOGOUT, req, {
      headers: headers
    });
  }

}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from './app.constants';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'myApp';
  isOpen : boolean = false;
  isLogoutOptionOpen : boolean = false;
  isShowJapaneseTitle : boolean = false;
  

  constructor(
    public translate: TranslateService,
    public _authService: AuthService,
    private _router: Router
  ) {
      localStorage.removeItem(Constants.REFRESH_TOKEN);
      localStorage.removeItem(Constants.EXPIRES_AT);
      localStorage.removeItem(Constants.ACCESS_TOKEN);
      localStorage.removeItem(Constants.USER_DETAILS);
      translate.addLangs(['en','ja']);
      const defaultLang = localStorage.getItem(Constants.DEFAULT_LANGUAGE);
      if (defaultLang && defaultLang === 'ja') {
        translate.setDefaultLang('ja');
        this.isShowJapaneseTitle = true;
      } else {
        translate.setDefaultLang('en');
      }
      
  }

  toggleLangMenu() {	
    this.isOpen = !this.isOpen;	
    if (this.isLogoutOptionOpen) {
      this.isLogoutOptionOpen = !this.isLogoutOptionOpen;
    }
  }

  

  toggleLogoutMenu() {	
    this.isLogoutOptionOpen = !this.isLogoutOptionOpen;	
    if (this.isOpen) {
      this.isOpen = !this.isOpen;
    }
  }	

  // toggleLogoutMenu() {	
  //   this.isLogoutOptionOpen = !this.isLogoutOptionOpen	
  // }	

  changeLang(lang: string) {	
    localStorage.setItem(Constants.DEFAULT_LANGUAGE, lang);
    if (lang === 'en') {
      this.isShowJapaneseTitle = false;
      localStorage.removeItem(Constants.DEFAULT_LANGUAGE);
    } else {
      this.isShowJapaneseTitle = true;
    }
    this.translate.use(lang);	
    this.toggleLangMenu();
  }

  logout() {
    if (this.isOpen) {
      this.toggleLangMenu();
    }
    this._authService.logout().subscribe((data) => {
      localStorage.removeItem(Constants.ACCESS_TOKEN);
      localStorage.removeItem(Constants.REFRESH_TOKEN);
      localStorage.removeItem(Constants.EXPIRES_AT);
      localStorage.removeItem(Constants.USER_DETAILS);
      localStorage.removeItem(Constants.DEFAULT_LANGUAGE);
      this._authService.userStatus = Constants.UNAUTHENTICATED;
      this._authService.isLoggedIn = false;
      this._router.navigate(['/login']);
      this.toggleLogoutMenu();
    })
  }

  // logout() {
  //   this._authService.logout().subscribe((data) => {
  //     localStorage.removeItem(Constants.ACCESS_TOKEN);
  //     localStorage.removeItem(Constants.REFRESH_TOKEN);
  //     localStorage.removeItem(Constants.EXPIRES_AT);
  //     localStorage.removeItem(Constants.USER_DETAILS);
  //     this._authService.userStatus = Constants.UNAUTHENTICATED;
  //     this._authService.isLoggedIn = false;
  //     this._router.navigate(['/login']);
  //   })
  // }
}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { Disease } from './../../interfaces/disease-interface';

@Component({
  selector: 'app-change-subscription',
  templateUrl: './change-subscription.component.html',
  styleUrls: ['./change-subscription.component.css']
})
export class ChangeSubscriptionComponent implements OnInit {


  constructor
  (
    private _subscription: SubscriptionService,
    private _router: Router,
    private _changeDetector: ChangeDetectorRef
  ) {}

  externalSensorId: string = '';
  cropSubscription: any;
  cropTypeDisease: any;
  cropDefaultValue: string = '';
  predictionDisease: any;
  selectedDisease: any;
  newSelectedDisease: string[] = [];
  newCropSelected: string = '';
  showLoader: boolean = true;
  cropName: string = '';
  isCropSelected: boolean = true;

  cropDiseaseSubscriptionForm: any = {
    externalSensorId: null,
    crop: null
  };

  ngOnInit(): void {
    this.externalSensorId=this._subscription.externalSensorId;
    this._subscription.changeSubscription(this._subscription.externalSensorId).subscribe(res => {
      this.cropSubscription = res;
      this.cropName = this.cropSubscription.cropName === undefined ? '' : this.cropSubscription.cropName;
      this._subscription.cropTypeDisease()?.subscribe(res => {
        
        
        this.cropTypeDisease = res;
        this.cropDefaultValue = this.cropSubscription.cropName;
        this._changeDetector.detectChanges();
        
      });
      this.getAllDiseases(this.cropSubscription.crop);
    }) 
  }

  getAllDiseases(crop: string) {
    this._subscription.predictionDisease(crop)?.subscribe(res => {

      const allDiseases = res;
      this.predictionDisease = allDiseases.map((item: any) => {
        this.showLoader = false;
        if (this.cropSubscription.diseases !== undefined) {
          const matchingObj = this.cropSubscription.diseases.find((obj: any) => {
              return obj.code === item.code;
          })
          return {
            ...item,
            isSelected: matchingObj === undefined ? false : true
          }
        } else {
          return {
            ...item,
            isSelected: false
          }
        }
      })
      this.showLoader = false;
    });
  }
  
  cancelPage() {
    this._router.navigate(['/landing']);
  }

  diseaseForCrop() {
    this.showLoader = true;
    this.isCropSelected = true;
    this.findNewCrop()
    this.getAllDiseases(this.newCropSelected);
  }

  findNewCrop() {
    for(let i = 0; i < this.cropTypeDisease.length; i++) {
      if (this.cropTypeDisease[i].description === this.cropDefaultValue) {
        this.cropName = this.cropTypeDisease[i].description;
        this.newCropSelected = this.cropTypeDisease[i].code;
      }
    }
  }

  cropDiseaseSubscription() {
    this.isCropSelected = true;
    if (this.cropDefaultValue === undefined) {
      this.isCropSelected = false;
      return;
    }
    this.showLoader = true;
    this.predictionDisease.map((disease: any) => {
      if (disease.isSelected) {
        this.newSelectedDisease.push(disease.code);
      }
    })
    this.findNewCrop();
    const cropDiseaseSubscriptionObj = {
      externalId: this.cropSubscription.externalId === undefined ? this.externalSensorId : this.cropSubscription.externalId,
      crop: this.newCropSelected,
      diseaseList: this.newSelectedDisease
    };

    this._subscription.cropDiseaseSubscription(cropDiseaseSubscriptionObj).subscribe(res => {
      this._router.navigate(['/new-result'])
    })
  }

}

<div class="container">
    <main class="container__main">
        <div class="login-box">
            <app-loader *ngIf="showLoader"></app-loader>
            <form name="loginForm" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
                <div  *ngIf="emailAddress.errors !== null && f.submitted">
                    <div *ngIf="emailAddress.errors.required" class="alertSummary ng-hide" role="alert">
                        {{ 'UI.COMMON.LOGIN_NAME_REQUIRED_MSG' | translate }}
                    </div>
                    <!-- <div *ngIf="emailAddress.errors.pattern">{{ 'UI.ERRORS.MESSAGE.INVALID_EMAIL_PATTERN' | translate }}</div> -->
                </div>
                <div *ngIf="password.errors !== null && f.submitted">
                    <div *ngIf="password.errors.required" class="alertSummary ng-hide" role="alert" >
                        {{ 'UI.COMMON.PASSWORD_REQUIRED_MSG' | translate }}
                    </div>
                    <!-- <div *ngIf="password.errors.minlength">
                        {{ 'UI.ERRORS.MESSAGE.PASSWORD_MIN_LENGTH' | translate }}
                    </div> -->
                </div>
                <div class="alertSummary ng-hide" role="alert" *ngIf="f.submitted && isLoginFailed">
                    {{ 'UI.COMMON.E0009' | translate }}
                </div>

                <div class='loginForm'>
                    <h3 
                        class="text-center ng-binding" 
                        style="font-family: 'Helvetica Neue', sans-serif; font-size: 24px; font-weight: 500; margin: 20px 20px 15px;">
                        {{ 'UI.COMMON.LOGIN_BUTTON' | translate }}
                    </h3>

                    <!-- <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Email address</label>
                        <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
                      </div> -->
                    <div class="form-group">
                        <label for="emailAddress">{{ 'UI.COMMON.EMAIL.LABEL' | translate }}</label><span class="mandatory_field">*</span>
                        <input 
                            type="text" 
                            class="form-control" 
                            name="emailAddress" 
                            placeholder="{{ 'UI.COMMON.LOGIN_USERNAME_PLACEHOLDER' | translate }}"
                            pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                            [(ngModel)]="loginForm.emailAddress" 
                            #emailAddress="ngModel" 
                            required 
                        />
                    </div>
                    <br>
                    <div class="form-group">
                        <label for="password">{{ 'UI.COMMON.PASSWORD' | translate }}</label><span class="mandatory_field">*</span>
                        <input 
                            type="{{ passwordInputType }}" 
                            class="form-control" 
                            name="password" 
                            [(ngModel)]="loginForm.password"
                            #password="ngModel"
                            required 
                            minlength="6"  
                        />
                    </div> <br>
                    <!-- <div class="form-group">
                        <button class="btn btn-primary btn-block">Login</button>
                    </div> -->
                    <div class="form-group">
                        <div class="checkbox">
                            <label class="checkbox-label secondary-label">
                                <input name="showPassword" type="checkbox" (click)="showTypingPassword()" [(ngModel)]="showPassword" />
                                  {{ 'UI.COMMON.SHOW_PASSWORD' | translate }}
                            </label>
                        </div>
                    </div> <br>
                    <div class="controls center-block form-group">
                        <button  class="form-control btn btn-primary center-block">
                            {{ 'UI.COMMON.LOGIN_BUTTON' | translate }}
                        </button>
                    </div> <br>
                </div>
            </form>
        </div>
    </main>
</div>

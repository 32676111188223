import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Constants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(private http: HttpClient) { }

  public externalSensorId!: string;

  checkUserPermission(sensorExternalId: string): Observable<any> {
    const url = Constants.API_ROUTES.CHECK_USER_PERMISSION + sensorExternalId;
    return this.http.get(url);
  }

  changeSubscription(sensorExternalId: string): Observable<any> {
    const url = Constants.API_ROUTES.CROP_SUBSCRIPTION + sensorExternalId;
    const defaultLanguage = localStorage.getItem(Constants.DEFAULT_LANGUAGE);
    if (defaultLanguage !== undefined && defaultLanguage === 'ja') {
      const headers = new HttpHeaders({
        'Accept-Language': defaultLanguage
      });
      return this.getChangeSubscription(url, headers);
    } else {
      return this.getChangeSubscription(url);
    }
  }
  getChangeSubscription(url: string, httpHeaders?: HttpHeaders): Observable<any> {
    if (httpHeaders) {
      return this.http.get(url, {headers: httpHeaders} );
    } else {
      return this.http.get(url);
    }
  }

  cropTypeDisease() {
    const userDetails = localStorage.getItem('user_details');
    if (userDetails) {
      const userRegion = JSON.parse(userDetails).region;
      const url = `${Constants.API_ROUTES.CROP_TYPE_DISEASE}/${userRegion}`;
      const defaultLanguage = localStorage.getItem(Constants.DEFAULT_LANGUAGE);
      if (defaultLanguage !== undefined && defaultLanguage === 'ja') {
        const headers = new HttpHeaders({
          'Accept-Language': defaultLanguage
        });
        return this.getCropTypeDisease(url, headers);
      } else {
        return this.getCropTypeDisease(url);
      }
    }
    return;
  }

  getCropTypeDisease(url: string, httpHeaders?: HttpHeaders): Observable<any> {
    if (httpHeaders) {
      return this.http.get(url, {headers: httpHeaders} );
    } else {
      return this.http.get(url);
    }
  }

  predictionDisease(cropName: string) {
    const userDetails = localStorage.getItem('user_details');
    if (userDetails) {
      const userRegion = JSON.parse(userDetails).region;
      const url = `${Constants.API_ROUTES.PREDICTION_DISEASE}/${cropName}/predictionDiseases/${userRegion}`;
      const defaultLanguage = localStorage.getItem(Constants.DEFAULT_LANGUAGE);
      if (defaultLanguage !== undefined && defaultLanguage === 'ja') {
        const headers = new HttpHeaders({
          'Accept-Language': defaultLanguage
        });
      return this.getPredictionDisease(url, headers);
      } else {
        return this.getPredictionDisease(url);
      } 
    }
    return;
  }

  getPredictionDisease(url: string, httpHeaders?: HttpHeaders): Observable<any> {
    if (httpHeaders) {
      return this.http.get(url, {headers: httpHeaders} );
    } else {
      return this.http.get(url);
    }
  }

  cropDiseaseSubscription(cropDiseaseSubscriptionReq: any) {
    const url = Constants.API_ROUTES.CROP_DISEASE_SUBSCRIPTION;
    return this.http.put(url, cropDiseaseSubscriptionReq);
  }
}

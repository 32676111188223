import { environment } from 'src/environments/environment';

export class Constants {

    // tokens
    static readonly TOKEN_HEADER_KEY = 'Authorization';
    static readonly ACCESS_TOKEN = 'access_token';
    static readonly REFRESH_TOKEN = 'refresh_token';
    static readonly EXPIRES_AT = 'expired_time';
    static readonly USER_DETAILS = 'user_details';
    static readonly USER_AUTHENTICATED = 'authenticated';
    static readonly PENDING_VERIFICATION = 'pending_verification';
    static readonly UNAUTHENTICATED = 'unauthenticated';
    static readonly DEFAULT_LANGUAGE = 'default_language';
    static readonly ACCEPT_LANGUAGE = 'Accept-Language';


    static readonly API_HOST = environment.apiUrl;

    static readonly API_ROUTES = {
        LOGIN: `${Constants.API_HOST}/public/auth/token`,
        CHECK_USER_PERMISSION: `${Constants.API_HOST}/api/v1/support/cropSubscription/checkUserPermission?sensorExternalId=`,
        CROP_SUBSCRIPTION: `${Constants.API_HOST}/api/v1/support/cropSubscription?isAllowNullSubscription=true&isCheckUserRegion=true&sensorExternalId=`,
        CSV_ALL_TIME:`${Constants.API_HOST}/api/v1/support/csvAllTime`,
        CSV_WITH_PARAM:`${Constants.API_HOST}/api/v1/support/csv`,
        CROP_TYPE_DISEASE: `${Constants.API_HOST}/api/v1/crop_types_disease`,
        PREDICTION_DISEASE: `${Constants.API_HOST}/api/v1/cropTypeCode`,
        SEND_PASSCODE_EMAIL:`${Constants.API_HOST}/api/v1/passcode/send`,
        VERIFY_PASSCODE:`${Constants.API_HOST}/api/v1/passcode/verify/`,
        CROP_DISEASE_SUBSCRIPTION: `${Constants.API_HOST}/api/v1/support/cropDiseaseSubscription`,
        LOGOUT: `${Constants.API_HOST}/public/auth/logout`
    };
}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-new-result',
  templateUrl: './new-result.component.html',
  styleUrls: ['./new-result.component.css']
})
export class NewResultComponent implements OnInit {

  externalSensorId : string = '';
  diseaseList : any;
  cropName : string='';
  showLoader: boolean = true;

  constructor(private _subcription: SubscriptionService, private _router: Router) { }
  ngOnInit(): void {
    this.externalSensorId = this._subcription.externalSensorId;
    this._subcription.changeSubscription(this.externalSensorId).subscribe(data => {
      this.cropName = data.cropName;
      this.diseaseList = data.diseases;
      this.showLoader = false;
    })
  }

  backToSearch() : void {
    this._router.navigate(['/landing']);
  }
}

import { Injectable } from '@angular/core';
import { Constants } from '../app.constants';
import { UserDetailsInterface } from '../interfaces/user-details-interface';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  public saveToken(accessToken: string, refreshToken: string, expiresAt: string, userDetails?: UserDetailsInterface): void {
    localStorage.setItem(Constants.ACCESS_TOKEN, accessToken);
    localStorage.setItem(Constants.REFRESH_TOKEN, refreshToken);
    localStorage.setItem(Constants.EXPIRES_AT, expiresAt);
    this.decodeAccessToken(accessToken);
  }

  decodeAccessToken(accessToken: string) {
    const encodedAccessToken : any = jwt_decode(accessToken);
    const userDetails = {
      email: encodedAccessToken.email,
      fujiUserId: encodedAccessToken.fujiUserId,
      name: encodedAccessToken.name,
      region: encodedAccessToken.region
    }
    localStorage.setItem(Constants.USER_DETAILS, JSON.stringify(userDetails));
  }

  public getToken(): string | null {
    return localStorage.getItem(Constants.ACCESS_TOKEN);
  }
}

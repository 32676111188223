import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from './app.constants';
import { AuthService } from './services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private _router: Router, private _authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    switch (this._authService.userStatus) {
      case Constants.UNAUTHENTICATED:
        this._router.navigate(['/login']);
        break;
      case Constants.PENDING_VERIFICATION:
        this._router.navigate(['/passcode']);
        break;
      default:
        break;
    }

    return true;
  }

}
<div class="container">
    <main class="main_container">
        <div class='box'>
            <app-loader *ngIf="showLoader"></app-loader>
            <h3 class="text-center">{{ 'UI.COMMON.NEW_STATUS' | translate }}</h3>
            <form novalidate>
                <div class='loginForm'>
                    <div class="form-group" >
                        <div class="row">
                            <label class="col-md-12" for="externalSensorId">{{ 'UI.COMMON.RESULT' | translate }}</label>
                        </div>
                        <div class="result-box">
                            <div>{{ externalSensorId }}</div>
                            <div>{{ 'UI.COMMON.CROP' | translate }}: {{ cropName }}</div>
                            <div>{{ 'UI.COMMON.DISEASE_LIST' | translate }}: </div>
                            <li>
                                <ul *ngFor="let d of diseaseList"> {{ d.description }}</ul>
                            </li>
                        </div>
                        <div class="actionButtons">
                            <button class="btn btn-primary form-control subs-btn" (click)="backToSearch()">{{ 'UI.COMMON.BACK_TO_SEARCH' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>
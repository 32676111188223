<div class="container">
    <main class="main_container">
        <div class='box'>
            <app-loader *ngIf="showLoader"></app-loader>
            <h3 class="text-center">{{ 'UI.COMMON.DISEASE_SUBSCRIPTION' | translate }}</h3>
            <form name="searchSensorForm"  (ngSubmit)="f.form.valid && searchSensorId()" #f="ngForm" novalidate>
                <div class="alertSummary ng-hide" role="alert" *ngIf="sensorDoesNotBelongToRegional">
                    {{ 'UI.COMMON.E0111' | translate }}
                </div>
                <div class="alertSummary ng-hide" role="alert" *ngIf="showPatternError">
                        <div>{{ 'UI.COMMON.E0026' | translate }}</div>
                </div>
                <div *ngIf="f.submitted && externalSensorId.errors !== null">
                    <div class="alertSummary" *ngIf="externalSensorId.errors.required">
                        <div>{{ 'UI.COMMON.E0026' | translate }}</div>
                    </div>
                </div>
                <div class='loginForm'>
                    <div class="form-group" >
                        <div class="row">
                            <label class="col-md-12" for="externalSensorId">{{ 'UI.COMMON.ENTER_SENSOR_TH_ID' | translate }}<span class="mandatory_field">*</span></label>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-8">
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    name="externalSensorId" 
                                    [(ngModel)]="searchSensorForm.externalSensorId" 
                                    #externalSensorId="ngModel"
                                    required 
                                />
                                
                            </div>
                            <div class="col-md-4">
                                <button class="btn btn-primary form-control resend-btn">{{ 'UI.COMMON.SEARCH_BUTTON' | translate }}</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </form>

            <h3 class="text-center">{{ 'UI.COMMON.DOWNLOAD_LOG' | translate }}</h3>
            <form  (ngSubmit)="go()" #f="ngForm" novalidate>
                <div class='loginForm'>
                    <div class="form-group" >
                        <div class="row">
                            <label class="col-md-10" for="externalSensorId">{{ 'UI.COMMON.DOWNLOAD_DISEASE_SUBSCRIPTION_LOG' | translate }}</label>
                            <button class="btn btn-primary form-control resend-btn goBtn">{{ 'UI.COMMON.GO' | translate }}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'src/app/app.constants';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.css']
})
export class AccountLoginComponent {

  showPassword: boolean = false;
  passwordInputType: string = 'password';
  showLoader : boolean = false;
  isLoginFailed : boolean = false;

  constructor(
    private _authService: AuthService,
    private _tokenStorage: TokenStorageService,
    private _router: Router
  ) {}

  loginForm: any = {
    emailAddress: null,
    password: null
  };


  showTypingPassword = () => !this.showPassword ? this.passwordInputType = 'text' : this.passwordInputType = 'password';


  onSubmit(): void {
    this.showLoader = true;
    this._authService.login(this.loginForm.emailAddress, this.loginForm.password).subscribe(res => {
      this._tokenStorage.saveToken(res.access_token, res.refresh_token, res.expired_time);
      this._authService.userStatus = Constants.PENDING_VERIFICATION;
      this._router.navigate(['/passcode']);
    }, err => {
      if (err.error.returnCode === 'E0029') {
        this.isLoginFailed = true;
        this.showLoader = false;
      }
    })
  }
}


import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AccountLoginComponent } from './components/accounts/account-login/account-login.component';
import { ValidatePasscodeComponent } from './components/accounts/validate-passcode/validate-passcode.component';
import { LandingComponent } from './components/landing/landing.component';
import { authInterceptorProviders } from './helpers/auth.interceptor';
import { ChangeSubscriptionComponent } from './components/change-subscription/change-subscription.component';
import { NewResultComponent } from './components/new-result/new-result.component';
import { LoaderComponent } from './components/loader/loader.component';
import { DownloadLogComponent } from './components/download-log/download-log.component';
import { NgbAlertModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { provideUserIdleConfig } from 'angular-user-idle';

@NgModule({
  declarations: [
    AppComponent,
    AccountLoginComponent,
    ValidatePasscodeComponent,
    LandingComponent,
    ChangeSubscriptionComponent,
    NewResultComponent,
    LoaderComponent,
    DownloadLogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgbDatepickerModule,
    NgbAlertModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  
  
  providers: [
    authInterceptorProviders,
    provideUserIdleConfig({ idle: 900, timeout: 10, ping: 10 })
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Constants } from 'src/app/app.constants';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionService } from 'src/app/services/subscription.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit{

  constructor
  (
    private _subscrition: SubscriptionService,
    private _router: Router,
    private userIdle: UserIdleService,
    public _authService: AuthService,
  ) {}
  ngOnInit(): void {
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(count => console.log(count));
    this.userIdle.onTimeout().subscribe(() => 
      {
        console.log('on timeout logging out ****** ');
        this._authService.logout().subscribe((data) => {
          console.log('logging out called  ######## ');
          localStorage.removeItem(Constants.ACCESS_TOKEN);
          localStorage.removeItem(Constants.REFRESH_TOKEN);
          localStorage.removeItem(Constants.EXPIRES_AT);
          localStorage.removeItem(Constants.USER_DETAILS);
          localStorage.removeItem(Constants.DEFAULT_LANGUAGE);
          this._authService.userStatus = Constants.UNAUTHENTICATED;
          this._authService.isLoggedIn = false;
          this._router.navigate(['/login']);
          this.userIdle.stopWatching();
        })
      }
    );
  }

  showPatternError: boolean = false;
  showLoader: boolean = false;
  sensorDoesNotBelongToRegional: boolean = false;
  searchSensorForm: any = {
    externalSensorId: null
  };

  sensorPattern: any = /^TH[A-Z0-9]{7}$/;

  searchSensorId() {
    this.showPatternError = false;

    if (!this.sensorPattern.test(this.searchSensorForm.externalSensorId)) {
      this.showPatternError = true;
      return;
    }
    this.showLoader = true;
    this._subscrition.checkUserPermission(this.searchSensorForm.externalSensorId).subscribe(res => {
      this._subscrition.externalSensorId = this.searchSensorForm.externalSensorId;
      this._router.navigate(['/change-subscription']);
    }, err => {
      if (err.error.returnCode === 'E0111') {
        this.sensorDoesNotBelongToRegional = true;
        this.showLoader = false;
      }
    });
  }

  go() {
    this._router.navigate(['/download-log']);
  }

}

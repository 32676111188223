import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RouterTestingHarness } from '@angular/router/testing';
import { Observable } from 'rxjs';
import { Constants } from '../app.constants';

@Injectable({
  providedIn: 'root'
})
export class DownloadLog {
  constructor(private http: HttpClient) { }
  csvAllTime(): Observable<any> {
    return this.http.get(Constants.API_ROUTES.CSV_ALL_TIME);
  }

  csvWithParam(startTime: number, endTime: number): Observable<any> {
    const url = `${Constants.API_ROUTES.CSV_WITH_PARAM}?startTime=${startTime}&endTime=${endTime}`;
    return this.http.get(url);
  }

}
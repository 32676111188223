import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { DownloadLog } from 'src/app/services/download-log.service';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-download-log',
  templateUrl: './download-log.component.html',
  styleUrls: ['./download-log.component.css'],
  providers: [DatePipe]
})
export class DownloadLogComponent {

  disableInput: boolean = false;
  isAllTime: boolean = false;
  showLoader: boolean = false;
  fileName: string = 'DATA_AllTime';

  jpnColumnMapping = {
    'Id': 'id',
    'ユーザーId': 'userId',
    'ユーザー名': 'username' ,
    '外部Id': 'externalId',
    '作物': 'crop',
    '病害': 'diseaseList',
    '地域': 'Region',
    'オプション変更時間（日本標準時）': 'subscribeTs',
    '成功': 'success',
    'エラーメッセージ': 'errorMessage'
  };

  engColumnMapping = {
    'Id': 'id',
    'User Id': 'userId',
    'Username': 'username' ,
    'External Id': 'externalId',
    'Crop': 'crop',
    'Diseases': 'diseaseList',
    'Region': 'Region',
    'Logged Time (JST)': 'subscribeTs',
    'Is success?': 'success',
    'Error Message': 'errorMessage'
  };
  
  constructor(
    private _downloadLog: DownloadLog,
    private _router: Router,
    private datePipe: DatePipe,
    private _changeDetector: ChangeDetectorRef
  ) { }
  options = {
    title: 'User Details',
    feildSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    noDownload: false,
    showTitle: false,
    useBom: false,
    headers: ['Id', 'User Id', 'Username', 'External Id', 'Crop', 'Diseases', 'Region', 'Logged Time (JST)', 'Is success?', 'Error Message']
  };

  downloadLogForm: any = {
    startDate: null,
    endDate: null
  };

  model: any;

  cancelPage() {
    this._router.navigate(['/landing']);
  }

  checkAllTime() {
    this.isAllTime =!this.isAllTime;
  }

  exportToExcel(data: any[], fileName: string, columnMapping: any) {
    
    const headers = Object.keys(columnMapping);
    const mappedData: any[] = data.map(item => {
      const mappedItem: any = {};
      for (const key in columnMapping) {
        const columnKey = key as keyof typeof columnMapping; 
        mappedItem[key] = item[columnMapping[columnKey]];
      }
      return mappedItem;
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(mappedData, { header: headers });
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, fileName + '.xlsx');

  }

  downloadLog(): void {
    const defaultLanguage = localStorage.getItem('default_language');
    if (defaultLanguage === 'ja') {
      this.options.headers = ['Id','ユーザーId','ユーザー名','外部Id','作物','病害','地域','オプション変更時間（日本標準時）','成功?','エラーメッセージ'];
    }
    this.showLoader = true;
    if (this.disableInput == true) {
      this._downloadLog.csvAllTime().subscribe(res => {
        const data = res;
        this.formatCSVResponse(data, true);
        this.showLoader = false;
      })
    } else {
      const startTimestamp = new Date(
            this.downloadLogForm.startDate.year, 
            this.downloadLogForm.startDate.month - 1, 
            this.downloadLogForm.startDate.day
            ).getTime();
      
      const endTimestamp = new Date(
            this.downloadLogForm.endDate.year, 
            this.downloadLogForm.endDate.month - 1, 
            this.downloadLogForm.endDate.day
          ).getTime();
      
      this._downloadLog.csvWithParam(startTimestamp, endTimestamp).subscribe(res => {
        let startMonth = this.downloadLogForm.startDate.month;
        if (startMonth < 10) {
          startMonth = '0' + startMonth;
        } else {
          startMonth = '' + startMonth;
        }
        let startDay = this.downloadLogForm.startDate.day;
        if (startDay < 10) {
          startDay = '0' + startDay;
        } else {
          startDay = '' + startDay;
        }
        let endMonth = this.downloadLogForm.endDate.month;
        if (endMonth < 10) {
          endMonth = '0' + endMonth;
        } else {
          endMonth = ''+ endMonth;
        }
        let endday = this.downloadLogForm.endDate.day;
        if (endday < 10) {
          endday = '0' + endday
        } else {
          endday = '' + endday;
        }
        this.fileName = 'DATA_' + (this.downloadLogForm.startDate.year + startMonth + startDay + '_' +
                        this.downloadLogForm.endDate.year + endMonth + endday);
        const data = res;
        this.formatCSVResponse(data, false);
        this.showLoader = false;
      })
    }

  }

  formatCSVResponse(data: any, isAllTime: boolean) {
    for (let i = 0; i < data.length; i++) {
      const formattedDate = this.datePipe.transform(data[i].subscribeTs, 'yyyy-MM-dd HH:mm:ss', "Asia/Tokyo");
      data[i].subscribeTs = formattedDate
    }

    const { headers } = this.options;
    const user_details = localStorage.getItem("user_details");
    const regionIndex = headers.indexOf('Region');

    if (user_details) {
      const userDetails = JSON.parse(user_details);
      const region = userDetails.region;

      data = data.map((row: any) => {
        const updatedRow = { ...row };
        const entries = Object.entries(updatedRow);
        entries.splice(regionIndex, 0, ['Region', region]);
        const updatedObject = Object.fromEntries(entries);

        return updatedObject;
      });

    }
    if (isAllTime) {
      this.exportToExcel(data, this.fileName, this.chooseColumnMapping());
    } else {
      // new ngxCsv(data, this.fileName, this.options);
      this.exportToExcel(data, this.fileName, this.chooseColumnMapping());
    }
    
  }

  autoAssignEndDate() {
    const currDate = new Date();
    const year = currDate.getFullYear();
    const month = (currDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currDate.getDate().toString().padStart(2, '0');
    this.downloadLogForm.endDate = { year: currDate.getFullYear(), month: currDate.getMonth() + 1, day: currDate.getDate() };;
    this._changeDetector.detectChanges();
  }

  chooseColumnMapping() {
    const defaultLanguage = localStorage.getItem('default_language');
    if (defaultLanguage !== undefined && defaultLanguage === 'ja') {
      return this.jpnColumnMapping
    } else {
      return this.engColumnMapping
    }
  }

}
